













import Component from 'vue-class-component';
import Vue from 'vue';
import EinfachGastLogo from '@/components/einfach-gast-logo.vue';
import { IVisitConditions } from '@/interfaces/i-visit-conditions';

@Component({
  name: 'VenueLogo',
  components: {
    EinfachGastLogo
  }
})
export default class VenueLogo extends Vue {
  visitConditions: IVisitConditions = null;

  get logoUrl () {
    return this.$visitConditionsStore.visitConditions?.logoUrl;
  }

  get venueName () {
    return this.$visitConditionsStore.visitConditions?.venueName;
  }
}
