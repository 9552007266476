

































































































































import Component from 'vue-class-component';
import Vue from 'vue';
import VenueLogo from '@/components/venue-logo.vue';
import EinfachGastLogo from '@/components/einfach-gast-logo.vue';
import sessionStorageHelper from '@/helpers/session-storage-helper';
import { Visit } from '@/models/visit';
import { ICompanion, IVisit } from '@einfachgast/shared';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'Finished',
  components: {
    VenueLogo,
    EinfachGastLogo
  }
})
export default class Finished extends Vue {
  visit: IVisit = new Visit();
  loading = false;

  @Prop()
  qrId: string;

  get scanAppFinishText () {
    return this.visitConditions?.scanAppFinishText?.[this.$i18n.locale] || this.$t('plsLetOpenThePageForServicePersonal');
  }

  get visitConditions () {
    return this.$visitConditionsStore.visitConditions;
  }

  get isAdminCode () {
    return this.$visitConditionsStore.visitConditions?.isAdminCode;
  }

  get startDate () {
    if (!this.visit) {
      return '';
    }
    return (this.visit.start as Date)?.toLocaleDateString();
  }

  get startTime () {
    if (!this.visit) {
      return '';
    }
    return (this.visit.start as Date)?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }

  get endTime () {
    if (!this.visit) {
      return '?';
    }
    if (!this.visit.end) {
      return '?';
    }
    return new Date(this.visit.end)?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }

  get hasCompanions () {
    if (!this.visit) {
      return false;
    }
    return this.visit.companions &&
      this.visit.companions.length > 0;
  }

  get canDisplaySocialLinks () {
    return this.isFacebookLink || this.isGoogleFeedbackLink;
  }

  get isFacebookLink () {
    return this.visitConditions?.facebookLink !== '';
  }

  get isGoogleFeedbackLink () {
    return this.visitConditions?.googleFeedbackLink !== '';
  }

  get canDisplayMenuBtn () {
    return this.visitConditions?.menuName && this.visitConditions?.menuUrl &&
      this.visitConditions?.menuName !== '' && this.visitConditions?.menuUrl !== '';
  }

  get visitorInfos () {
    if (!this.visit) {
      return '';
    }
    return `${this.visit.firstname} ${this.visit.lastname}, ${this.visit.zipcode} ${this.visit.city}`;
  }

  get companionInfos () {
    if (!this.visit) {
      return '';
    }

    return (this.visit.companions || []).map((x: ICompanion) => x.name).join(', ');
  }

  get personCount () {
    if (!this.visit) {
      return 1;
    }
    return 1 + (this.visit.companions || []).length;
  }

  get areaLabel () {
    if (!this.visitConditions || !this.visit) {
      return '';
    }
    if (!this.visitConditions.areas) {
      return '';
    }
    const area = this.visitConditions.areas.find(x => x.id === this.visit.areaId);
    return area?.name;
  }

  async created () {
    try {
      await this.$visitConditionsStore.initVisitConditions(this.qrId.trim());
      const visitData = sessionStorageHelper.loadVisitorData();
      if (!visitData) {
        this.$router.push({ name: '404' });
      }
      this.visit = new Visit(visitData);
    } catch (e) {
      this.$router.push({ name: '404' });
    }

    this.loading = false;
  }

  newCheckin () {
    this.$router.push({ path: `/qr/${this.qrId.trim()}` });
  }

  openMenuUrl () {
    window.open(this.visitConditions.menuUrl, '_blank');
  }

  openFacebookUrl () {
    window.open(this.visitConditions.facebookLink);
  }

  openGoogleUrl () {
    window.open(this.visitConditions.googleFeedbackLink);
  }
}
