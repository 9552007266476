
import { IVisitConditionsStore } from '@/interfaces';
import { IVisitStore } from '@/interfaces/i-visit-store';
import Vue from 'vue';
import Vuex from 'vuex';
import { VisitConditionsStoreModule } from './visit-conditions-store-module';
import { VisitStoreModule } from './visit-store-module';

Vue.use(Vuex);

export interface IStore {
  VisitConditions: IVisitConditionsStore;
  Visit: IVisitStore;
}

// store.vuex.ts
const store = new Vuex.Store<IStore>({
  modules: {
    VisitConditions: VisitConditionsStoreModule,
    Visit: VisitStoreModule
  }
});

export { store };
