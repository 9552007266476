import { CheckInResultDisplayType } from '@/enums/checkin-result-display-types';
import { ICheckInResult } from '@/interfaces';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export class CheckInResultDisplay extends Vue {
  displayToastByCheckInResult (saveCheckInResult: ICheckInResult) {
    if (!saveCheckInResult) {
      return;
    }
    if (saveCheckInResult.displayType === CheckInResultDisplayType.Toast && saveCheckInResult.message && saveCheckInResult.displayDuration) {
      this.$buefy.toast.open({
        message: this.$t(saveCheckInResult.message, saveCheckInResult.translateData).toString(),
        position: 'is-top',
        type: (!saveCheckInResult.success) ? 'is-danger' : 'is-success',
        duration: saveCheckInResult.displayDuration,
        queue: false
      });
    } else if (saveCheckInResult.displayType === CheckInResultDisplayType.Dialog && saveCheckInResult.message) {
      this.$buefy.dialog.alert({
        message: this.$t(saveCheckInResult.message, saveCheckInResult.translateData).toString()
      });
    }
  }
}
