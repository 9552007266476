
























import Component from 'vue-class-component';
import EinfachGastLogo from '@/components/einfach-gast-logo.vue';
import Vue from 'vue';
import localStorageHelper from '@/helpers/local-storage-helper';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'CookieScreen',
  components: {
    EinfachGastLogo
  }
})
export default class CookieScreen extends Vue {
  loading = false;

  @Prop()
  redirectUrl: string;

  get hasAcceptedCookies () {
    return localStorageHelper.hasAcceptedCookies();
  }

  async acceptAndProceed () {
    this.loading = true;
    localStorageHelper.acceptCookies();
    this.$router.push({ path: this.redirectUrl });
  }
}
