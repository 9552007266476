









































































































import Component, { mixins } from 'vue-class-component';
import CompanionForm from '@/components/companion-form.vue';
import EinfachGastLogo from '@/components/einfach-gast-logo.vue';
import MyDataForm from '@/components/my-data-form.vue';
import SafetyHints from '@/components/safety-hints.vue';
import VenueLogo from '@/components/venue-logo.vue';
import VenueIntroText from '@/components/venue-intro-text.vue';
import VenueAreaLabel from '@/components/venue-area-label.vue';
import Vue from 'vue';
import localStorageHelper from '@/helpers/local-storage-helper';
import { IVisit, ICompanion } from '@einfachgast/shared';
import { Visit } from '@/models/visit';
import { Companion } from '@/models/companion';
import { ValidationError } from '@/classes/validation-error';
import { Prop } from 'vue-property-decorator';
import { CheckInModes } from '@/enums/checkin-modes';
import { RouteNames } from '@/router';
import { CheckInResultDisplay } from '@/mixins/checkin-result-display';

const validate = (visit: IVisit, validationGroups: { groups: string[] }, vue: Vue): ValidationError[] => {
  const errors: ValidationError[] = [];

  // eslint-disable-next-line no-useless-escape
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (visit.email !== '' && !emailRegex.test(visit.email)) {
    errors.push({
      target: visit,
      property: 'email',
      value: visit.email,
      constraints: {
        required: vue.$t('plsEnterAValidEmail').toString()
      }
    });
  }

  // Define fields that have a required min-length
  const minLengthFields = [
    { key: 'firstname', minLength: 2 },
    { key: 'lastname', minLength: 2 },
    { key: 'street', minLength: 2 },
    { key: 'city', minLength: 2 },
    { key: 'zipcode', minLength: 4 },
    { key: 'phone', minLength: 4 }
  ];

  minLengthFields.forEach(f => {
    if (!(visit as any)[f.key] || (visit as any)[f.key].length < f.minLength) {
      errors.push({
        target: visit,
        property: f.key,
        value: (visit as any)[f.key],
        constraints: {
          'min-length': vue.$t('requiredFieldMindLength', { minLength: f.minLength }).toString()
        }
      });
    }
  });

  // Required fields
  const requiredFields = [
    'areaId',
    'start'
  ];

  requiredFields.forEach(f => {
    if (!(visit as any)[f]) {
      errors.push({
        target: visit,
        property: f,
        value: (visit as any)[f],
        constraints: {
          required: `${vue.$t('requiredField')}.`
        }
      });
    }
  });

  return errors;
};

const validateCompanion = (companion: ICompanion, validationGroups: { groups: string[] }, vue: Vue): ValidationError[] => {
  const errors: ValidationError[] = [];

  // Define fields that have a required min-length
  const minLengthFields = [
    { key: 'name', minLength: 2 },
    { key: 'phone', minLength: 4 }
  ];

  minLengthFields.forEach(f => {
    if (!(companion as any)[f.key] || (companion as any)[f.key].length < f.minLength) {
      errors.push({
        target: companion,
        property: f.key,
        value: (companion as any)[f.key],
        constraints: {
          'min-length': vue.$t('requiredFieldMindLength', { minLength: f.minLength }).toString()
        }
      });
    }
  });

  return errors;
};
@Component({
  name: 'VisitForm',
  components: {
    CompanionForm,
    MyDataForm,
    VenueLogo,
    EinfachGastLogo,
    SafetyHints,
    VenueIntroText,
    VenueAreaLabel
  }
})
export default class VisitForm extends mixins(CheckInResultDisplay) {
  loading = false;
  errors: ValidationError[] = [];
  companionErrors: Array<ValidationError[]> = [];
  hasCompanions: boolean | null = null;
  termsAccepted = false;
  visit: IVisit = new Visit();
  termsNotacceptedErrorMessage: string = null;
  covidTestNotacceptedErrorMessage: string = null;
  safetyHintsNotAcceptedErrorMessage = '';
  safetyHintsAccepted = false;

  @Prop()
  qrId: string;

  get visitConditions () {
    return this.$visitConditionsStore.visitConditions;
  }

  get dataProtectionLinkTranslated () {
    const dataProtectionLinkWithOpenTag = '<a href="' + ((this.visitConditions.isUserVisit)
      ? `https://einfachgast.de/datenschutzerklaerung-app-besucherregistrierung?egVenue=${this.venueId}`
      : `https://einfachgast.de/datenschutzerklaerung-app?egVenue=${this.venueId}`) +
      '" target="_blank">';
    return this.$t('readAndApplyDataProtection', {
      linkOpen: dataProtectionLinkWithOpenTag,
      linkClose: '</a>'
    });
  }

  get isCheckinCheckoutMode () {
    return this.visitConditions?.checkinMode === CheckInModes.CheckInCheckOut;
  }

  get isUserVisit () {
    return this.visitConditions?.isUserVisit;
  }

  get saveButtonText () {
    if (this.isCheckinCheckoutMode) {
      return this.$t('checkin');
    }
    return this.$t('save');
  }

  get isLoading () {
    return this.$visitConditionsStore?.loading ||
      this.$visit?.loading;
  }

  get validationGroups () {
    const validationGroups = ['default'];

    if (this.visitConditions?.isAdminCode) {
      validationGroups.push('adminCode');
    }

    return validationGroups;
  }

  get venueId () {
    return this.visitConditions?.venueId;
  }

  get hasErrors () {
    return this.errors.length > 0 ||
      this.companionErrors.length > 0 ||
      !this.termsAccepted ||
      (this.visitConditions.covidOptions.showNegativeCheckbox &&
        !this.visit.covidTestCheckboxChecked);
  }

  get displaySafetyHints () {
    return this.visitConditions?.displaySafetyHints;
  }

  async mounted () {
    try {
      await this.$visitConditionsStore.initVisitConditions(this.qrId.trim());
      if (this.visitConditions.isAdminCode && this.visitConditions?.areas.length === 1) {
        /* Only set first area, when there is only one to prevent
        unwanted behavior of always selecting the first */
        this.visit.areaId = this.visitConditions?.areas[0].id;
      }
    } catch (e) {
      this.$router.push({ name: RouteNames.NotFound });
    }
    this.visit.duration = this.visitConditions?.duration;
    this.visit.displayedCovidNote = this.visitConditions.covidOptions.showNoteText;

    // If the safety hints have been displayed, we mark this in our model
    this.visit.displayedSafetyHints = this.displaySafetyHints;

    const existingData = localStorageHelper.loadVisitorData();
    if (!existingData || this.visitConditions?.isAdminCode) {
      return;
    }
    this.visit.initializeFromStorage(existingData);
  }

  async submit () {
    if (!this.termsAccepted) {
      this.termsNotacceptedErrorMessage = this.$t('plsApplyDatatProtection').toString();
      this.$buefy.toast.open({
        message: this.$t('plsApplyDatatProtection').toString(),
        position: 'is-bottom',
        type: 'is-danger'
      });
    }

    if (this.visitConditions.covidOptions.showNegativeCheckbox && !this.visit.covidTestCheckboxChecked) {
      this.covidTestNotacceptedErrorMessage = this.$t('plsApplyCoronaConditions').toString();
      this.$buefy.toast.open({
        message: this.$t('plsApplyCoronaConditions').toString(),
        position: 'is-bottom',
        type: 'is-danger'
      });
    }

    // Safety features accepted? (when applicable)
    if (this.displaySafetyHints && !this.visit.safetyHintsAccepted) {
      this.safetyHintsNotAcceptedErrorMessage = this.$t('safetyHintFormErrorMessage').toString();
      this.$buefy.toast.open({
        message: this.$t('safetyHintFormErrorMessage').toString(),
        position: 'is-bottom',
        type: 'is-danger'
      });
      return;
    }

    if (!this.isCheckinCheckoutMode) {
      this.visit.updateEndDate();
    }

    if (!this.visit.areaId && !this.visitConditions.isAdminCode) {
      this.visit.areaId = this.visitConditions?.areaId;
    }

    this.errors = validate(this.visit, { groups: this.validationGroups }, this);

    if (this.errors && this.errors.length > 0) {
      // if error = scroll auto to the visit form
      const visitForm = this.$el.querySelector('.visit-form');
      if (visitForm) {
        visitForm.scrollIntoView();
      }
    }

    this.companionErrors = await this.validateCompanions();

    // If there are any errors the user must resolve them first
    if (this.hasErrors) {
      return;
    }

    // Proceed actual save or Checkin action
    let saveCheckInResult = null;
    if (this.isCheckinCheckoutMode) {
      saveCheckInResult = await this.$visit.checkIn({ visit: this.visit, asAdmin: this.visitConditions.isAdminCode });
    } else {
      saveCheckInResult = await this.$visit.save({ visit: this.visit, asAdmin: this.visitConditions.isAdminCode });
    }
    this.displayToastByCheckInResult(saveCheckInResult);

    if (this.visitConditions?.isAdminCode) {
      this.visit = new Visit();
      if (this.visitConditions?.areas.length === 1) {
        /* Only set first area, when there is only one to prevent
        unwanted behavior of always selecting the first */
        this.visit.areaId = this.visitConditions?.areas[0].id;
      }
      (this.$refs.myDataForm as MyDataForm).setupMinuteTickerInterval();
      window.scrollTo(0, 0);
    }
    this.termsAccepted = false;
    this.safetyHintsAccepted = false;
  }

  async addCompanion () {
    this.companionErrors = await this.validateCompanions();
    if (this.companionErrors && this.companionErrors.length > 0) {
      return;
    }
    this.visit.companions.push(new Companion({
      name: '',
      phone: ''
    }));
  }

  async validateCompanions () {
    const errors: Array<ValidationError[]> = [];
    for (const index in this.visit.companions) {
      const error = await validateCompanion(this.visit.companions[index], { groups: this.validationGroups }, this);
      if (error && error.length > 0) {
        errors[index] = error;
      }
    }
    return errors;
  }

  removeCompanion (index: number) {
    this.visit.companions.splice(index, 1);
    this.companionErrors.splice(index, 1);
  }
}
