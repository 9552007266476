

















import Component from 'vue-class-component';
import Vue from 'vue';
import EinfachGastLogo from '@/components/einfach-gast-logo.vue';
@Component({
  name: 'NotFound',
  components: {
    EinfachGastLogo
  }
})
export default class NotFound extends Vue {}
