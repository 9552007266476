













































































































































































import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { IVisitConditions } from '@/interfaces';
import { ValidationError } from '../classes/validation-error';
import { CheckInModes } from '@/enums/checkin-modes';
import { IVisit } from '@einfachgast/shared';

@Component({
  name: 'MyDataForm'
})
export default class MyDataForm extends Vue {
  minuteTicker: number = null;

  @Prop()
  value!: IVisit;

  @Prop()
  visitConditions: IVisitConditions;

  @Prop()
  errors!: ValidationError[];

  get isAdminQrCode () {
    return this.visitConditions?.isAdminCode;
  }

  get isCheckinCheckoutMode () {
    return this.visitConditions?.checkinMode === CheckInModes.CheckInCheckOut;
  }

  get mustUpdateStartTime () {
    return this.visitConditions?.isAdminCode && this.visitConditions?.checkinMode === CheckInModes.Standard;
  }

  mounted () {
    this.setupMinuteTickerInterval();
  }

  beforeDestroy () {
    this.clearMinuteTickerInterval();
  }

  clearMinuteTickerInterval () {
    clearInterval(this.minuteTicker);
  }

  setupMinuteTickerInterval () {
    if (this.mustUpdateStartTime) {
      this.minuteTicker = window.setInterval(() => {
        this.value.start = new Date();
      }, 60000);
    }
  }

  /**
   * Retrieve field errors state by name
   * @param {string} fieldName the name of the field
   * @return {string} the bulma name for the current state
   */
  getFieldColor (fieldName: string) {
    const fieldErrors = this.getFieldErrorsAsString(fieldName);
    if (fieldErrors && fieldErrors) {
      return 'is-danger';
    }
    return '';
  }

  /**
   * Finds, and Merges all error Messages Regarding the given Fieldname
   * @param {string} fieldName the name of the field
   * @return {string} the messages
   */
  getFieldErrorsAsString (fieldName: string) {
    const fieldErrors = this.errors.filter(error => error.property === fieldName);
    if (!fieldErrors) {
      return;
    }
    return fieldErrors.map(x => Object.values((x.constraints || {})).map(t => this.$t(t))).join(',');
  }

  /**
   * Remove all entries from errors array, that are matching the given field name
   * @param {string} fieldName the name of the field we want to clear errors of
   */
  clearErrors (fieldName: string) {
    const index = this.errors.findIndex(x => x.property === fieldName);
    if (index === -1) {
      return;
    }
    this.errors.splice(index, 1);
  }
}
