




































import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Vue from 'vue';

@Component({
  name: 'SafetyHints'
})
export default class SafetyHints extends Vue {
  @Prop()
  error: boolean;

@Prop()
  errorMsg: string;

  @Prop()
  value: boolean;

  safetyHints = [
    {
      text: 'safetyHintWearVisitorBadge',
      icons: ['hint-01-visitor-badge.png']
    },
    {
      text: 'safetyHintProtectiveClothing',
      icons: ['hint-02-vest.png', 'hint-02-boots.png', 'hint-02-ear-protection.png']
    },
    {
      text: 'safetyHintForklift',
      icons: ['hint-03-forklift.png']
    },
    {
      text: 'safetyHintSmoking',
      icons: ['hint-04-smoking.png']
    },
    {
      text: 'safetyHintSpeedLimit',
      icons: ['hint-05-speed-limit.png']
    },
    {
      text: 'safetyHintPhotography',
      icons: ['hint-06-photography.png']
    },
    {
      text: 'safetyHintEmergencyInstructor',
      icons: ['hint-07-emergency-exit.png', 'hint-07-gathering-point.png']
    },
    {
      text: 'safetyHintEmergencyBehavior',
      icons: ['hint-08-emergency.png']
    }

  ]
}
