import { ICompanion, IStorageData, IVisit } from '@einfachgast/shared';
import { Companion } from './companion';

export class Visit implements IVisit {
  constructor (data?: IVisit) {
    if (!data) {
      return;
    }

    Object.keys(data).forEach(key => {
      if (key === 'companions') {
        this.companions = data.companions.map(x => new Companion(x));
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any)[key] = (data as any)[key] || (this as any)[key];
      }
    });
  }

  companions: ICompanion[] = [];
  duration = 60; // default value for old venues
  end!: Date;

  areaId: string = null;

  firstname = '';

  lastname = '';

  street = '';

  city = '';

  zipcode = '';

  phone = '';

  email = '';

  displayedSafetyHints = false;
  safetyHintsAccepted = false;

  start: Date = new Date();

  covidTestCheckboxChecked = false;
  displayedCovidNote = false;

  /**
   * populate properties with values from Local storage
   * @param storageData The Data from Previos visits we have stored
   */
  initializeFromStorage (storageData: IStorageData) {
    this.firstname = storageData.firstname;
    this.lastname = storageData.lastname;
    this.street = storageData.street;
    this.zipcode = storageData.zipcode;
    this.city = storageData.city;
    this.email = storageData.email;
    this.phone = storageData.phone;
  }

  /**
   * Calculate end Date by duration
   * @return {Visit} this
   */
  updateEndDate () {
    this.end = new Date(
      this.start.getTime() + this.duration * 60000
    );
    return this;
  }

  toFirestorageFormat () {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: any = Object.assign({}, this);
    result.start = result.start.getTime();
    result.end = result.end?.getTime();
    return result;
  }
}
