import { CheckInModes } from '@/enums/checkin-modes';
import { IArea, ICovidOptions, IVisitConditions } from '@/interfaces';
import { IVenueOwnerSettings } from '@einfachgast/shared';

export class VisitConditions implements IVisitConditions {
  venueId = '';
  venueName = '';
  backgroundColor = '';
  webFontColor = '';
  logoUrl = '';
  areaId = '';
  isAdminCode = false;
  isUserVisit = false;
  introText: {[key: string]: string} = null;
  menuUrl = '';
  menuName = '';
  settings: IVenueOwnerSettings = null;
  checkinMode: CheckInModes = CheckInModes.Standard;
  facebookLink = '';
  googleFeedbackLink = '';
  areas: IArea[] = [];
  duration = 60; // default value for old venues
  maxStayDuration: number = null;
  covidOptions: ICovidOptions = null;
  scanAppFinishText: { [key: string]: string } = null;
  displaySafetyHints?: boolean = false;

  private crucialFields: string[] = [
    'venueId',
    'venueName',
    'areaId'
  ];

  constructor (data?: IVisitConditions) {
    if (!data) {
      return;
    }
    Object.keys(this)
      .filter(x => x !== 'crucialFields')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .forEach(x => { (this as any)[x] = (data as any)[x] || (this as any)[x]; });
  }

  validateOrFail () {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errorousFields = this.crucialFields.filter(x => !(this as any)[x]);
    if (errorousFields.length > 0) {
      throw new Error(`${errorousFields.join(', ')} must not be empty`);
    }
  }
}
