import { ICompanion } from '@einfachgast/shared';

export class Companion implements ICompanion {
  constructor (data?: ICompanion) {
    if (!data) {
      return;
    }

    Object.keys(data).forEach(key => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this as any)[key] = (data as any)[key];
    });
  }

  name = '';

  phone = '';
}
