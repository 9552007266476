import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import VisitForm from '../views/VisitForm.vue';
import CheckedOut from '../views/CheckedOut.vue';
import CheckedIn from '../views/CheckedIn.vue';
import NotFound from '../views/NotFound.vue';
import Finished from '../views/Finished.vue';
import CookieScreen from '@/views/CookieScreen.vue';
import localStorageHelper from '@/helpers/local-storage-helper';

Vue.use(VueRouter);

export enum RouteNames {
  VisitForm = 'VisitForm',
  Finished = 'Finished',
  CookieScreen = 'CookieScreen',
  CheckedIn = 'CheckedIn',
  CheckedOut = 'CheckedOut',
  NotFound = '404',
}

const routes: Array<RouteConfig> = [
  /**
   * i.E. ueberland/tisch/1
   * The Main Form where the visit data is entered
   */
  {
    path: '/qr/:qrId',
    name: RouteNames.VisitForm,
    props: true,
    component: VisitForm
  },
  /**
   * i.E. ueberland/finished
   * The confirmation Page that is displayed after the main form is submitted
   */
  {
    path: '/:qrId/finished',
    name: RouteNames.Finished,
    props: true,
    component: Finished
  },
  /**
   * i.E. /checkedIn
   * The checked In view
   */
  {
    path: '/checkedIn',
    name: RouteNames.CheckedIn,
    props: route => ({ redirectUrl: route.query.redirectUrl }),
    component: CheckedIn
  },
  {
    path: '/checkedOut',
    name: RouteNames.CheckedOut,
    component: CheckedOut
  },
  /**
   * Splash screen with cookie consent
   */
  {
    path: '/accept',
    name: RouteNames.CookieScreen,
    meta: {
      public: true
    },
    props: route => ({ redirectUrl: route.query.redirectUrl }),
    component: CookieScreen
  },
  /**
   * 404
   */
  {
    path: '/404',
    name: RouteNames.NotFound,
    meta: {
      public: true
    },
    props: true,
    component: NotFound
  },
  {
    path: '*',
    redirect: '404'
  }
];

const router = new VueRouter({
  routes,
  mode: 'history'
});

/**
 * This Route Guard:
 * Makes sure that Users have accepted cookies before we place any of them
 */
router.beforeEach(async (to, from, next) => {
  const acceptedCookies = localStorageHelper.hasAcceptedCookies();
  // cookie screen can be accessed without any action
  if (to.name === RouteNames.CookieScreen) {
    next();
    return;
  }

  // If target is anything except cookieScreen, and cookies are not yet accepted,
  // Redirect to cookie screen
  if (!acceptedCookies) {
    next({ name: RouteNames.CookieScreen, query: { redirectUrl: to.fullPath } });
    return;
  }

  // Visitors with a checkout Token are checked in and must therefore only visit the checked in Screen
  if (to.name !== RouteNames.CheckedIn && localStorageHelper.loadCheckoutToken()) {
    next({ name: RouteNames.CheckedIn, query: { redirectUrl: to.fullPath } });
    return;
  }

  // and move on
  next();
});

export default router;
