import { IVisitConditionsStore } from '@/interfaces';
import { store } from '@/store';
import { VisitConditionsStoreModule } from '@/store/visit-conditions-store-module';
import _Vue from 'vue';
import { getModule } from 'vuex-module-decorators';

export function VisitConditionsPlugin (Vue: typeof _Vue): void {
  Vue.prototype.$visitConditionsStore = getModule(VisitConditionsStoreModule, store);
}

declare module 'vue/types/vue' {
  // eslint-disable-next-line @typescript-eslint/interface-name-prefix
  interface Vue {
    $visitConditionsStore: IVisitConditionsStore;
  }
}
