import 'reflect-metadata';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './assets/scss/bulma.scss';
import SplashScreen from '@/components/splash-screen.vue';
import BuefyIconRenderer from '@/components/buefy-icon-renderer.vue';
import { SentryService } from '@/helpers/sentry/sentry-service';
import * as deTranslation from './translation/de';
import * as enTranslation from './translation/en';

/* eslint-disable import/no-duplicates */
import { ConfigProgrammatic } from 'buefy';
import Button from 'buefy';
import CheckBox from 'buefy';
import Collapse from 'buefy';
import Field from 'buefy';
import Icon from 'buefy';
import Image from 'buefy';
import Input from 'buefy';
import Loading from 'buefy';
import Message from 'buefy';
import NumberInput from 'buefy';
import Select from 'buefy';
import Timepicker from 'buefy';
import { VisitConditionsPlugin } from './plugins/visit-conditions-plugin';
import visibility from 'vue-visibility-change';
import { VisitsPlugin } from './plugins/visits-plugin';
import VueI18n from 'vue-i18n';

class AppBootstrap {
  constructor () {
    this.loadVueApp();
  }

  private loadVueApp () {
    Vue.use(VisitConditionsPlugin);
    Vue.use(VisitsPlugin);
    Vue.component('buefy-icon-renderer', BuefyIconRenderer);
    Vue.use(Button);
    Vue.use(Field);
    Vue.use(Select);
    Vue.use(Icon);
    Vue.use(Input);
    Vue.use(Timepicker);
    Vue.use(NumberInput);
    Vue.use(Loading);
    Vue.use(Image);
    Vue.use(Collapse);
    Vue.use(Message);
    Vue.use(CheckBox);
    Vue.use(VueI18n);
    Vue.use(visibility);

    ConfigProgrammatic.setOptions({
      defaultIconComponent: 'buefy-icon-renderer'
    });

    Vue.component('SplashScreen', SplashScreen);

    Vue.config.productionTip = false;

    const sentryService = new SentryService();
    Vue.config.errorHandler = function (err, vm, info) {
      sentryService.captureDefaultErrorHandler(err, vm, info);
      throw err;
    };

    const defaultLang = navigator.language ? navigator.language.split('-')[0] : 'de';
    const i18n = new VueI18n({
      locale: defaultLang,
      messages: {
        de: deTranslation.default,
        en: enTranslation.default
      }
    });

    new Vue({
      i18n,
      router,
      render: h => h(App)
    }).$mount('#app');
  }
}

// eslint-disable-next-line no-new
new AppBootstrap();
