import { render, staticRenderFns } from "./language-switch.vue?vue&type=template&id=6246d79d&scoped=true&"
import script from "./language-switch.vue?vue&type=script&lang=ts&"
export * from "./language-switch.vue?vue&type=script&lang=ts&"
import style0 from "./language-switch.vue?vue&type=style&index=0&id=6246d79d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6246d79d",
  null
  
)

export default component.exports