import { IStylingHelper, IVisitConditions } from '@/interfaces';

export class StylingHelper implements IStylingHelper {
  private _actions: Array<(visitConditions: IVisitConditions) => void> = [];

  constructor () {
    this.registerDefaults();
  }

  applyStyles (visitConditions: IVisitConditions): void {
    try {
      this._actions.map(action => action(visitConditions));
    } catch (e) {
      // mute on purpose because style changes should not affect the general functionality of the app
    }
  }

  registerStylingAction (action: (visitConditions: IVisitConditions) => void) {
    this._actions.push(action);
  }

  private registerDefaults () {
    const setBodyBackgroundColor = (visitConditions: IVisitConditions) => {
      if (visitConditions.backgroundColor) {
        document.body.style.background = visitConditions.backgroundColor;
      }
    };

    this.registerStylingAction(setBodyBackgroundColor);
  }
}
const stylingHelper = new StylingHelper();
export { stylingHelper };
