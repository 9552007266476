


























import Component from 'vue-class-component';
import Vue from 'vue';

@Component
export default class LanguageSwitch extends Vue {
  menuIsOpen = false;

  get visitConditions () {
    return this.$visitConditionsStore.visitConditions;
  }

  get multipleLanguagesAvailable () {
    return (this.visitConditions?.settings?.activeLanguages?.length || 0) > 1;
  }

  mounted () {
    this.$watch(() => this.$visitConditionsStore.loading, (loading: boolean) => {
      if (loading === false) {
        if (this.visitConditions.settings.activeLanguages.indexOf(this.$i18n.locale) === -1) {
          this.$i18n.locale = this.visitConditions.settings.defaultLanguage;
        }
        this.$i18n.fallbackLocale = this.visitConditions.settings.defaultLanguage;
      }
    });
  }
}
