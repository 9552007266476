import { IArea, IVisitConditions, IVisitConditionsStore } from '@/interfaces';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
// eslint-disable-next-line @typescript-eslint/camelcase
import jwt_decode from 'jwt-decode';
import sessionStorageHelper from '../helpers/session-storage-helper';
import { cloudFunctions } from '../firebase-cloud-functions';
import { VisitConditions } from '@/models/visit-conditions';
import { stylingHelper } from '@/helpers/styling-helper';

@Module({ namespaced: true, name: 'VisitConditions' })
export class VisitConditionsStoreModule extends VuexModule implements IVisitConditionsStore {
  private _visitConditions: IVisitConditions = null;
  private _loading = true;

  get loading () {
    return this._loading;
  }

  get area (): IArea | null {
    return this._visitConditions?.areas?.find(area => area.id === this._visitConditions.areaId);
  }

  get areaLabel (): string {
    return this.area?.name || '';
  }

  @Mutation
  setLoading (loading: boolean) {
    this._loading = loading;
  }

  get visitConditions () {
    return this._visitConditions;
  }

  @Mutation
  setVisitConditions (visitConditions: IVisitConditions) {
    this._visitConditions = visitConditions;
  }

  @Action
  async initVisitConditions (qrCodeId: string) {
    if (this.visitConditions) {
      return;
    }
    this.context.commit('setLoading', true);

    if (!qrCodeId || typeof qrCodeId !== 'string') {
      throw new Error(`Invalid Qr code Id "${qrCodeId}"`);
    }

    const visitConditionsResponse = await cloudFunctions.call('retrieveVisitConditions', { qrCodeId });

    const result = visitConditionsResponse.data.result;

    // Make sure we have a valid jwt this will throw if not.
    jwt_decode(result.token);

    const visitConditions = new VisitConditions(result.visitConditions);
    visitConditions.validateOrFail();

    sessionStorageHelper.saveVenueToken(result.token);
    stylingHelper.applyStyles(visitConditions);

    this.context.commit('setVisitConditions', visitConditions);
    this.context.commit('setLoading', false);
  }

  @Action
  async reInitVisitConditions (conditionsData: IVisitConditions) {
    const visitConditions = new VisitConditions(conditionsData);
    visitConditions.validateOrFail();
    stylingHelper.applyStyles(visitConditions);
    this.context.commit('setVisitConditions', visitConditions);
    this.context.commit('setLoading', false);
  }
}
