

















































import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Vue from 'vue';
import { ICompanion } from '@einfachgast/shared';
import { ValidationError } from '@/classes/validation-error';

@Component({
  name: 'CompanionForm'
})
export default class CompanionForm extends Vue {
  @Prop()
  value!: ICompanion;

  @Prop()
  index!: number;

  @Prop({ default: () => ([] as ValidationError[]) })
  errors!: ValidationError[];

  get phoneError () {
    const fieldErrors = this.errors?.filter(error => error.property === 'phone');
    if (!fieldErrors) {
      return;
    }
    return fieldErrors.map(x => Object.values((x.constraints || {}))).join(',');
  }

  get nameError () {
    const fieldErrors = this.errors?.filter(error => error.property === 'name');
    if (!fieldErrors) {
      return;
    }
    return fieldErrors.map(x => Object.values((x.constraints || {}))).join(',');
  }

  /**
   * Remove all entries from errors array, that are matching the given field name
   * @param {string} fieldName the name of the field we want to clear errors of
   */
  clearErrors (fieldName: string) {
    const index = this.errors.findIndex(x => x.property === fieldName);
    if (index === -1) {
      return;
    }
    this.errors.splice(index, 1);
  }

  remove () {
    this.$emit('remove', this.index);
  }

  clear () {
    this.value.name = '';
    this.value.phone = '';
  }
}
