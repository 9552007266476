import { render, staticRenderFns } from "./venue-intro-text.vue?vue&type=template&id=eaee8b5e&scoped=true&"
import script from "./venue-intro-text.vue?vue&type=script&lang=ts&"
export * from "./venue-intro-text.vue?vue&type=script&lang=ts&"
import style0 from "./venue-intro-text.vue?vue&type=style&index=0&id=eaee8b5e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eaee8b5e",
  null
  
)

export default component.exports